/**
 * ヘッダのナビゲーションをいいがにする
 * 
 *  - (モバイル) ボタンでナビゲーションを開閉する
 *  - (共通) 上向きスクロールした場合のみナビゲーションを固定表示する
 */

import { updateScrollVisible } from './scrollVisible'

/** @type {number} 「深い」スクロールとするピクセル数 */
const deepScrollBreakpoint = 160

export function initNav() {
	const root = document.documentElement
	const body = document.body

	// ボタンでの開閉
	{
		const toggler = document.querySelector('.hd-nav-toggle')
		let collapse = true

		toggler.addEventListener('click', () => {
			collapse = !collapse
			root.classList[collapse ? 'remove' : 'add']('hd-nav-active')

			updateScrollVisible()
		})
	}

	// 上向きスクロールしたときにクラスを付与する
	{
		let prevTop = 0
		let prevDeep = false
		let prevDirection = 'none'
		const scrollHandler = () => {
			// Firefox, Chrome, IE11 は前者、Edge は後者
			const top = root.scrollTop + body.scrollTop

			const deep = (top >= deepScrollBreakpoint)
			if (deep !== prevDeep) {
				root.classList[deep ? 'add' : 'remove']('scroll-deep')
				prevDeep = deep
			}

			let direction = 'none'
			if (top < prevTop) {
				direction = 'up' // 上向きスクロール
			} else if (top > prevTop) {
				direction = 'down' // 下向きスクロール
			}
			if (direction !== prevDirection) {
				if (direction !== 'none') {
					root.classList.remove(`scroll-${prevDirection}`)
					root.classList.add(`scroll-${direction}`)
				}
				prevDirection = direction
			}

			prevTop = top
		}

		window.addEventListener('scroll', scrollHandler)
		scrollHandler()
	}
}
