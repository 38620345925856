/**
 * <body> にスクロールが発生しているかどうかを調べ、
 * もしスクロールが発生していればルート要素にクラスを設定する
 */

/**
 * <body> にスクロールが発生しているかどうかを調べる
 * @return {boolean}
 */
export function scrollVisible() {
	const root = document.documentElement
	return root.scrollHeight > window.innerHeight
}

/** @type {?boolean} */
let prevStatus = null

/**
 * ルート要素のクラスを更新する
 */
export function updateScrollVisible() {
	const hasScrollBar = scrollVisible()
	if (hasScrollBar !== prevStatus) {
		const root = document.documentElement
		root.classList[hasScrollBar ? 'add' : 'remove']('scroll-visible')
		prevStatus = hasScrollBar
	}
}

/** @type {?function} */
let handler = null

/**
 * ウィンドウサイズを監視してルート要素のクラスを更新する
 */
export function watchScrollVisible() {
	if (handler) return
	handler = updateScrollVisible
	window.addEventListener('resize', handler)
	handler()
}

/**
 * ウィンドウサイズの監視をやめる
 */
export function unwatchScrollVisible() {
	if (!handler) return
	window.removeEventListener('resize', handler)
	handler = null
}
