class Scroll {

	constructor (el) {
		this.el = el
	}

	getScrollPosition () {
		if (this.el) {
			return {
				top: this.el.scrollTop,
				left: this.el.scrollLeft
			}
		} else {
			const { documentElement, body } = document
			return {
				top: documentElement.scrollTop || body.scrollTop,
				left: documentElement.scrollLeft || body.scrollLeft
			}
		}
	}

	// targetTop, targetLeft まで絹のようになめらかにスクロールさせる
	toPosition (targetTop, targetLeft, aDuration) {
		const start = this.getScrollPosition()
		var deltaY = (targetTop == null) ? 0 : targetTop - start.top
		var deltaX = (targetLeft == null) ? 0 : targetLeft - start.top
		var duration = (aDuration == null) ? 400 : aDuration
		var startTime = Date.now()
		const target = this.el

		const toPosition_ = () => {
			var frac = Math.min(1, (Date.now() - startTime) / duration)
			if (target) {
				target.scrollTop = start.top + deltaY * frac
				target.scrollLeft = start.left + deltaX * frac
			} else {
				window.scrollTo(start.left + deltaX * frac, start.top + deltaY * frac)
			}
			if (frac < 1) {
				setTimeout(toPosition_, 10)
			}
		}

		toPosition_()
	}

	toElement (element, offsetY, offsetX, duration) {
		const position = this.offset(element)
		var top, left

		offsetY = offsetY || 0
		offsetX = offsetX || 0

		if (position) {
			top = position.top - offsetY
			if (top < 0) {
				top = 0
			}
			left = position.left - offsetX
			if (left < 0) {
				left = 0
			}
			this.toPosition(top, left, duration)
		}
	}

	offset (el) {
		const rect = el.getBoundingClientRect()
		const scroll = this.getScrollPosition()
		return {
			left: rect.left + scroll.left,
			top: rect.top + scroll.top
		}
	}
}

export default Scroll
