const rawCharsRE = /[&<>"\u0060\u007E-\uD7FF\uE000-\uFFEF]|[\uD800-\uDBFF][\uDC00-\uDFFF]/g // "
const entityRE = /&#([a-z0-9]+);?|&([a-z0-9]+);/gi

/// @var [string : string]
const baseEntities = {
	'"': '&quot;',
	"'": '&#39;',
	'<': '&lt;',
	'>': '&gt;',
	'&': '&amp;',
	'\u0060': '&#96;'
}

/// @var [string : string]
const reverseEntities = {
	'&lt;': '<',
	'&gt;': '>',
	'&amp;': '&',
	'&quot;': '"',
	'&apos;': "'"
}

const asciiMap = {
	128: '\u20AC',
	130: '\u201A',
	131: '\u0192',
	132: '\u201E',
	133: '\u2026',
	134: '\u2020',
	135: '\u2021',
	136: '\u02C6',
	137: '\u2030',
	138: '\u0160',
	139: '\u2039',
	140: '\u0152',
	142: '\u017D',
	145: '\u2018',
	146: '\u2019',
	147: '\u201C',
	148: '\u201D',
	149: '\u2022',
	150: '\u2013',
	151: '\u2014',
	152: '\u02DC',
	153: '\u2122',
	154: '\u0161',
	155: '\u203A',
	156: '\u0153',
	158: '\u017E',
	159: '\u0178'
}

const Entities = {

	/// @param text: string
	/// @return string
	encode: function (text) {
		return text.replace(rawCharsRE, function (chr) {
			return baseEntities[chr] || chr
		})
	},

	/// @param text: string
	/// @return string
	decode: function (text) {
		return text.replace(entityRE, function (all, numeric) {
			if (numeric) {
				if (numeric.charAt(0).toLowerCase() === 'x') {
					numeric = parseInt(numeric.substr(1), 16)
				} else {
					numeric = parseInt(numeric, 10)
				}

				// 長い UTF
				if (numeric > 0xFFFF) {
					numeric -= 0x10000
					return String.fromCharCode(0xD800 + (numeric >> 10), 0xDC00 + (numeric & 0x3FF))
				}
				return asciiMap[numeric] || String.fromCharCode(numeric)
			}
			return reverseEntities[all] || nativeDecode(all)
		})
	}
}

/// 機械的にデコードできなかった文字は DOM 経由でデコードする
function nativeDecode (text) {
	var tmpE

	tmpE = document.createElement('div')
	tmpE.innerHTML = text

	return tmpE.textContent || tmpE.innerText || text
}

export default Entities
