let active = false

const toggler = document.querySelector('.hd-nav-child > li > .nav-item')
const togglee = document.querySelector('.hd-nav-dropdown')

if (toggler && togglee) {
	toggler.addEventListener('click', (event) => {
		event.preventDefault()
		active = !active
		togglee.classList[active ? 'remove' : 'add']('collapse-mobile')
	})
}
